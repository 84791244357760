.music-container {
    width: 75%;
    margin-left: 15%;
}

.music-title {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.music-body {
    font-size: .8rem;
    text-align: justify;
}

.music-quote {
    font-size: .5rem;
    font-weight: bold;
    padding: 5px 10%;
    text-align: center;
    color: rgb(80, 80, 80);
}

.music-links {
    display: flex;
    margin: .5rem;
    border: 2px inset grey;
    padding: 5px;
    background-color: rgb(180, 180, 180);
}

.music-links a {
    color: navy;
    font-weight: bold;
    font-size: .7rem;
    text-decoration: none;
}

.music-links a:hover {
    color: rgb(0, 0, 180);
    text-shadow: .5px .5px navy;
}

.link-column {
    flex: 50%;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .music-container {
        width: 90%;
        margin-left: 5%;
    }
}