.voidman-popup {
    position: fixed;
    bottom: 10px; 
    right: 10px;
    z-index: 1000; 
    transition: transform 1s;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    cursor: crosshair;
  }

  @keyframes riseUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

.voidman-image {
    display: none;
  }

  @keyframes glitched {
    0% {
        text-shadow: 1px 0 0 rgb(255, 0, 128), -2px 0 0 lime;
    }
    50% {
        text-shadow: -1px 0 0 lime, 2px 0 5 rgb(255, 0, 128);
    
    }
    75% {
        text-shadow: -2px -1px 5px rgb(92, 184, 17), 0px 1px 3px rgb(255, 0, 85);
    }
    
    100% {
        text-shadow: 2px 0.5px 3px rgb(255, 0, 85), -2px -1px 5px rgb(92, 184, 17);
    }
    }
  
  .voidman-image.visible {
    display: inline-block; 
    animation-name: riseUp;
    animation-duration: 4s; 
    animation-timing-function: ease; 
    animation-fill-mode: forwards; 
    width: 60%;
    height: auto;
  }

  .text-bubble {
    position: absolute;
    bottom: 120px; 
    right: 10px; 
    padding: auto;
    border-radius: 2px; 
    cursor: default;
    background-color: rgba(0, 0, 60, 0.9);
  }

  .text-bubble p {
    font-size: 8.5px;
    text-align: justify; 
    padding: 10px; 
  }
 
  
  .text-bubble button {
    background-color: rgb(0, 0, 160); 
    color: #fff; 
    border: none;
    border-radius: 5px; 
    padding: 5px 10px; 
    margin: 10px;
    cursor: pointer;
    outline: none;
    font-size: 7px;
    display: block; 
    margin: 2px auto; 
  }
  
  .text-bubble button:hover {
    background-color: #000080; 
    animation: glitched 0.01s infinite;
    animation-direction: alternate;
  }
  


  