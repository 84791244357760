body {
  font-family: 'Courier', monospace;
  font-size: small;
  cursor: default;
}

.body-text {
  color: var(--main-text-color);
  margin: auto;
  padding: 1rem;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    color: var(--main-text-color);
  }
  50% {
    color: transparent;
  }
  60% {
    text-shadow: none;
  }
  100% {
    color: var(--main-text-color);
  }
}

.splash-container {
  margin: auto;
  padding: 30px;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  min-width: 250px;
}

@media screen and (max-width: 700px) {
  .splash-container {
      max-width: 90%;
      padding: 0;
  }
}

.splash-type {
  color: lime;
  font-size: small;
  position: relative;
  background-color: black;
  text-decoration: none;
  padding: 5px;
  margin: 0px 35px 0px 35px;
  border: .5px solid lime;
}

.cursor {
  position: absolute;
  right: 0;
  width: 100%;
  height: 1rem;
  background: black;
  box-sizing: border-box;
  border-left: .15em solid;
}

@-webkit-keyframes typing {
  from,to { width: 100%; }
  25% { width: 0; }
  50% { width: 0; }
  75% { width: 100%; }
}

@keyframes typing {
  from { width: 100%; }
  to { width: 0; }
}

@keyframes blinking {
  from, to { border-color: transparent; }
  50% { border-color: lime; }
}