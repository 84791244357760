.video-responsive-tik, .video-responsive {
    overflow: hidden;
    position: relative;
    height: 0;
  }

.video-responsive {
    padding-bottom: 57%;
}

.video-responsive-tik {
    padding-bottom: 100%;
}

@media screen and (max-width: 700px) {
  .video-responsive-tik {
    padding-bottom: 170%;
  }
}

@media screen and (min-width: 900px) {
  .video-responsive-tik {
    padding-bottom: 100%;
  }
}
  
  .video-responsive iframe {
    height: 100%;
    width: 100%;
    position: absolute;
  }