header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background: rgb(15,15,15);
    background: linear-gradient(0deg, rgba(15,15,15,1) 0%, rgba(0,0,0,1) 100%);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.8);
  }
  
  header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: lime;
    visibility: hidden;
    opacity: 0;
  }
  
  /*mobile responsiveness*/
  @media only screen and (max-width: 700px){
    header .nav-btn {
      visibility: visible;
      opacity: 1;
      font-size: 1.6rem;
    }
  
    header .nav-btn:hover {
      animation: flicker 0.1s infinite 0.1s;
    }
  
    header .nav-btn:active {
      color: rgb(166, 255, 0);
    }
  
    header nav {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      background-color: rgba(0, 0, 0, 0.95);
      transition: .5s;
      transform: translateY(-100vh);
    }
  
    header .responsive_nav {
      transform: none;
    }
  
    nav .nav-close-btn {
      font-size: 2rem;
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  
    nav .nav-links {
      font-size: 1.5rem;
    }
  
    nav .nav-links:hover {
      font-size: 1.55rem;
    }
  
  }

  nav {
    cursor: pointer;
    z-index: 9999;
  }
  
  nav .nav-links {
    text-decoration: none;
    color: lime;
    text-shadow: 0px 0px 2px rgb(129, 255, 44);
    font-family: "Silkscreen", sans-serif;
    font-weight: 500;
    display: inline;
    padding: 25px;
  }

  .silkscreen-regular {
    font-family: "Silkscreen", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
 
  
  /*nav animation*/
  nav .nav-links:hover {
    font-weight: 900;
    text-shadow: 0px 0px 15px rgb(129, 255, 44);
    animation: flicker 0.1s infinite 0.1s;
  }
  
  @keyframes flicker {
    0% {opacity: 1}
    40% {opacity: 0.8}
    80% {opacity: 0.6}
    100% {opacity: 1}
  }
  
  nav .nav-links:active {
    color: rgb(208, 255, 0);
  }
  
  /*logo animation */
  .Logo:hover {
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 1000ms;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-delay: .5s;
  }
  
  @-webkit-keyframes wiggle {
    0% {-webkit-transform: rotate(0deg);}
    25% {-webkit-transform: rotate(-10deg);}
    50% {-webkit-transform: rotate(20deg) scale(1.4);}
    75% {-webkit-transform: rotate(-5deg);}
    100% {-webkit-transform: rotate(0deg);}
  }
  
  @-ms-keyframes wiggle {
    0% {-ms-transform: rotate(1deg);}
    25% {-ms-transform: rotate(-1deg);}
    50% {-ms-transform: rotate(1.5deg) scale(1.4);}
    75% {-ms-transform: rotate(-5deg);}
    100% {-ms-transform: rotate(0deg);}
  }
  
  @keyframes wiggle {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(-10deg);}
    50% {transform: rotate(20deg) scale(1.4);}
    75% {transform: rotate(-5deg);}
    100% {transform: rotate(0deg);}
  }