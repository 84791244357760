.home-container {
    min-height: 80vh;
    max-width: 100vw;
    margin: 1rem;
}

@media screen and (max-width: 700px) {
    .home-container {
        max-width: 95vw;
    }
}

.resizable-window {
    background-color: rgb(0, 128, 128);
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    border: 3px ridge rgb(129, 129, 129);
    cursor: default;
    box-shadow: 0px 0px 5px black;
    min-height: auto;
    min-width: 250px;
    max-width: 350px;
}

.home-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Courier', monospace;
    padding: 1rem;
    user-select: none;
}

.home-text:hover {
    animation-duration: 0.01s;
    animation-name: glitched;
    animation-delay: .5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: default;
}

@keyframes glitched {
0% {
    text-shadow: 1px 0 0 rgb(255, 0, 128), -2px 0 0 lime;
}
50% {
    text-shadow: -1px 0 0 lime, 2px 0 5 rgb(255, 0, 128);

}
75% {
    text-shadow: -2px -1px 5px rgb(92, 184, 17), 0px 1px 3px rgb(255, 0, 85);
}

100% {
    text-shadow: 2px 0.5px 3px rgb(255, 0, 85), -2px -1px 5px rgb(92, 184, 17);
}
}

.home-title{
    background:linear-gradient(90deg,#000080, rgb(0, 0, 160));    
    color: #fff;
    padding: 4px;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    text-shadow: none;
    line-height: 1.2rem;
    min-height: 1.8rem;
    max-height: 2rem;
    overflow: hidden;
    border: 3px outset rgb(0, 0, 160);
    grid-area: title;
    min-width: 100;
}

.home-title:hover {
    animation: glitched 0.01s infinite;
    animation-direction: alternate;
    animation-delay: 2s;
}

.fake-btn {
    float: right;
    border: 1px outset rgb(129, 129, 129);
    background-color: rgb(195, 195, 195);
    padding: 0px 6px 0px 6px;
    font-size: .8rem;
}

.fake-btn:hover {
    background-color: rgb(220, 220, 220);
}

.fake-btn:active {
    border: 1px inset rgb(129, 129, 129);
}

.fake-btn:focus {
    border: 1px dotted rgb(129, 129, 129);
}


.link-list {
    background-color: rgb(0, 128, 128);
    color: black;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    padding: .5rem;
}

.link-list:hover + .hidden-text,
.hidden-text:hover {
  opacity: 1;
}

.link-list a, li {
    text-decoration: none;
    text-shadow: 0px .5px 1px black;
    list-style-type: none;
    padding: .1rem;
    color: white;
    cursor: default;
    display: inline-grid;
    justify-items: center;
    box-sizing: border-box;
    text-align: center;
    font-size: x-small;
}

.link-list a:hover {
    transform: scale(1.1);
    text-shadow: 1px 1px 1px black;
}

.link-list a:active {
    transform: scale(.9);
}

.buynowlink {
    text-align: center;
}

.buynowlink:hover {
    animation-duration: 0.01s;
    animation-name: glitched;
    animation-delay: .1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: default;}

 
    .hidden-text {
        opacity: 0;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: rgba(0, 0, 0, 0);
        padding: 10px;
        border-radius: 5px;
        color: white;
        max-width: 300px;
        font-size: xx-small;
        transition: opacity 0.3s ease;
      }

.hidden-text a {
    text-decoration: none;
    font-size: xx-small;
    color: white;
    cursor: default;
    animation-duration: 0.01s;
    animation-name: glitched;
    animation-delay: .5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: help;
}



.footer {
  font-family: 'Courier', monospace;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.footer:hover {
    animation-duration: 0.01s;
    animation-name: glitched;
    animation-delay: .1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: default;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
