.video-container {
    width: 75%;
    margin-left: 15%;
}

@media screen and (max-width: 800px) {
    .video-container {
        width: 90%;
        margin-left: 5%;
    }
}

.video-titles {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.down-arrow {
    display: block;
    text-align: right;
    margin-top: -.8rem;
}

.video-sub {
    font-size: .8rem;
    font-weight: bold;
    padding: .5rem;
    border-radius: 3px;
    border: 2px solid black;
    background: linear-gradient(0deg, rgba(226,226,226,1) 0%, rgba(158,158,158,1) 100%);
}

.video-sub:hover, .video-sub:active {
    background: navy;
    color: white;
    border: 2px outset navy;
}

.video-description {
    padding: .2rem;
    border: 1px outset black;
    background: rgb(226,226,226);
    background: linear-gradient(0deg, rgba(226,226,226,1) 0%, rgba(158,158,158,1) 100%);
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: max-content;
}

.video-grid2 {
    display: grid;
    gap: 10px;
    border: 1px outset black;
}