* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 100vw;
    max-height: 100vh;
  }

  :root {
    --main-text-color: rgba(255, 252, 238, 0.75);
    --main-text-shadow: 0px 0px 5px white;
    --freak-text-color: lime;
  }
  
  body {
    background: rgb(0,22,255);
    background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    color: var(--main-text-color);
    text-shadow: var(--main-text-shadow);
    min-width: fit-content;
    min-height: fit-content;
    animation: dropout 30s infinite;
  }

  @keyframes dropout {
    0% {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    2%
    {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    2.5% {
      background-color: rgb(0,22,255);
      background-image: url(../public/Images/flashblue.png);
      background-size: cover;
    }

    3% {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    45%
    {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    45.5% {
      background-color: white;
      background-image: url(../public/Images/flashblue.png);
      background-size: cover;
    }

    46% {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    52%
    {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    52.5% {
      background-color: white;
      background-image: url(../public/Images/flashblue.png);
      background-size: contain;
    }

    53% {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }

    98% {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }
    
    98.5% {
      background: white;
      background-image: url(../public/Images/flashblue.png);
      background-size: cover;
    }
    99%
    {
      background: rgb(0,22,255);
      background: radial-gradient(circle, rgba(0,22,255,1) 40%, rgba(20,31,142,1) 100%);
    }
    }
  
  .freak-mode {
    transition-duration: .5s;
    transition-property: background-color;
    background-image: url("../public/Images/glitch.gif");
    background-size: cover;
    background-color: black;
    color: var(--freak-text-color);
    animation-duration: 0.03s;
    animation-name: glitched;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: default;
}

@keyframes glitched {
0% {
    text-shadow: 1px 0 0 rgb(255, 0, 128), -2px 0 0 lime;
}
50% {
    text-shadow: -1px 0 0 lime, 2px 0 5 rgb(255, 0, 128);

}
75% {
    text-shadow: -2px -1px 5px rgb(92, 184, 17), 0px 1px 3px rgb(255, 0, 85);
}

100% {
    text-shadow: 2px 0.5px 3px rgb(255, 0, 85), -2px -1px 5px rgb(92, 184, 17);
}
}

@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap');