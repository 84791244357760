/* PopupModal.css */

/* Semi-opaque overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  /* Modal container */
  .modal {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    letter-spacing: 0.001em;
    border: 3px outset rgb(129, 129, 129) ;
    background-color: rgb(195, 195, 195);
    box-shadow: 0px 0px 5px black;
    color: black;
    min-width: 50%;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    z-index: 10;
    cursor: default;
  }
  
  .modal {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    letter-spacing: 0.001em;
    border: 3px outset rgb(129, 129, 129) ;
    background-color: rgb(195, 195, 195);
    box-shadow: 0px 0px 5px black;
    color: black;
    min-width: 50%;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    z-index: 10;
    cursor: default;
}

@media screen and (max-width: 700px) {
    .modal {
        min-width: 80%;
    }
}

.modal-title{
    background: #000080;
    color: #fff;
    padding: .5rem .5rem .5rem .5rem;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    text-shadow: none;
    min-height: 1.5rem;
    max-height: 2rem;
    overflow: hidden;
}

.modal-body {
    padding: 8px;
    font-size: .9rem;
    min-height: 2rem;
    max-height: 35rem;
    overflow-y: auto;
}

.modal-button {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    margin: 4px;
    border: 1px outset rgb(129, 129, 129);
    background-color: rgb(195, 195, 195);
    padding: 0px 6px 0px 6px;
    font-size: 1rem;
    min-height: 1rem;
    min-height: 1rem;
}

.modal-button:hover {
    background-color: rgb(220, 220, 220);
}

.modal-button:active {
    border: 1px inset rgb(129, 129, 129);
}

.modal-button:focus {
    border: 1px dotted rgb(129, 129, 129);
  }

  /* Modal footer */
.modal-footer {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    text-align: center; 
  }
  
  /* Okay button */
  .okay-button {
    padding: 8px 16px;
    border: none;
    border-radius: 3px;
    border: 1px outset rgb(129, 129, 129);
    background-color: rgb(195, 195, 195);
    padding: 0px 6px 0px 6px;
    cursor: pointer;
    display: inline-block; 
    margin: 10px auto; 
  }
  
  