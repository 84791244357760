.about-intro {
    font-weight: bold;
    font-size: .8rem;
    text-align: center;
    margin-bottom: 1rem;
}

.about-text {
    padding: 0rem 1rem;
    text-align: justify;
    margin-bottom: 1rem;
}

.about-text a {
    color: navy;
    text-decoration: none;
    font-weight: bold;
    cursor: default;
}

.about-text a:hover {
    color: rgb(0, 0, 202);
    font-weight: bolder;
    text-shadow: 0px 0px .5px rgb(0, 0, 202);
}

.freak-text:hover {
    animation-duration: 0.03s;
    animation-name: glitched;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: default;
}

@keyframes glitched {
0% {
    text-shadow: 1px 0px 0px black, -2px 0px 0px yellow;
}
50% {
    text-shadow: -1px 0px 0px red, 2px 0px 1px black;

}
75% {
    text-shadow: -2px -1px 2px black, 0px 1px 0px white;
}

100% {
    text-shadow: 2px 0.5px .5px blue, -2px -1px 0px lime;
}
}

.faq-title {
    text-align: center;
    padding-top: 1rem;
}

.faq-question {
    font-weight: bold;
    border: 2px groove grey;
    background-color: navy;
    color: white;
    width: 100%;
    cursor: help;
    padding: 3px;
    margin: 2px 0px 2px 0px;
}

.faq-question:hover {
    background-color: rgb(0, 0, 202);
    text-shadow: 0px 0px .5px rgb(0, 0, 202);
}

.faq-answer {
    /* display: none; */
    overflow: hidden;
    padding: .5rem 1.5rem;
}

.faq-answer a {
    color: navy;
    text-decoration: none;
    font-weight: bold;
    cursor: default;
}

.faq-answer a:hover {
    color: rgb(0, 0, 202);
    text-shadow: 0px 0px .5px rgb(0, 0, 202);
}