.console-blink {
    animation: blink .8s infinite;
}

@keyframes blink {
    0% { color: white; }
    49% { color: white; }
    50% { color: transparent; }
    100% { color: transparent; }
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: black;
    background-color: black;
    overflow-x: hidden;
    z-index: 9999;
    transition: .5s;
}
  
  input[type="text"],
  input[type="password"] {
    background-color: rgb(15, 15, 15);
    padding: .2rem;
    font-family: 'Courier', monospace;
    color: rgb(192, 192, 192);
  }
  
  input[type="exit"], 
  input[type="submit"] {
  background-color: black;
    color: white;
    border: 1px solid rgb(20, 20, 20);;
    border-radius: 2px;
    padding: .2rem 1rem;
  }
  
  input[type="submit"]:hover {
    background-color: rgb(20, 20, 20);
  }
  
  .button-container {
    display: flex;
    justify-content: left;
  }
  
  .login-form {
    background-color: rgb(0, 0, 0);
    padding: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .list-container {
    display: flex;
  }
  
  .error {
    color: red;
    text-shadow: none;
  }
  
  .title {
    font-size: 12px;
  }
  
  .input-container {
    margin: 0;}

.console-btn {
    background-color: black;
    color: white;
    border: 1px solid rgb(20, 20, 20);;
    border-radius: 2px;
    padding: .2rem 1rem;
}

.console-btn:hover {
    background-color: rgb(20, 20, 20);
}