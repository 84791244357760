.alert-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    position: fixed;
    bottom: 0;
    animation: popup 0.4s;
    z-index: 1002;
  }
  
  @keyframes popup {
    0%{
      transform: translateY(100%);
    }
    100%{
      transform: translateY(0%);
    }
  }
  
  .alert {
    display: grid;
    justify-content: center;
    padding: 18px;
    width: 100vw;
    max-width: 600px;
    background-color: rgb(195, 195, 195);
    color: black;
    text-decoration: none;
    text-shadow: none;
    border: 3px ridge rgb(170, 170, 170);
    border-radius: 2px;
    box-shadow: 2px 2px 5px black;
    font-size: .7rem;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
  }
  
  .alert .close-btn {
    justify-content: center;
    align-items: center;
    font-size: .6rem;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    background-color: rgb(240,240,240);
    background-color: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(230, 230, 230) 100%);  
    border: none;
    border-radius: 2px;
    box-shadow: 1px 1px 3px black;
    padding: 5px;
    margin: auto;
    margin-top: .5rem;
    width: 20%;
  }
  
  .alert .close-btn:hover {
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.80);
  }
  
  .alert .close-btn:active {
    background-color: rgb(255, 255, 255);
    background-color: linear-gradient(0deg, rgb(240, 240, 240) 0%, rgb(230, 230, 230) 100%);  
    box-shadow: 0px 0px 3px black;
  }