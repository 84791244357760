.popups1, .popups2, .popups3, .popups4, .popups5, .popups6, .popups7, .cursed-pop, .visual-worm, .visual-worm2, .visual-worm3, .visual-worm4 {
      display: block;
      font-family: 'MS Sans Serif', 'Menlo', sans-serif;
      color: black;
      background: rgb(152, 158, 161);
      border: 2px outset rgb(129, 129, 129);
      box-shadow: 0px 0px 5px black;
      position: fixed;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */    
}

.popups1 {
    width: auto;
    height: auto;
    padding-bottom: 30px;    
    left: 20%;
    cursor: default;
    z-index: 31;
}

.popups2 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 15%;
    top: 60%;
    margin-left: -50px;
    z-index: 32;
}

.popups3 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 5%;
    top: 25%;
    z-index: 31;
}

.popups4 {
    width: auto;
    height: auto;
    padding-bottom: 30px;    
    left: 5%;
    top: 10%;
    margin-left: -10px;
    z-index: 31;
}

.popups5 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 40%;
    top: 65%;
    margin-left: -40px;
    z-index: 31;
}

.popups6 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 8%;
    top: 40%;
    margin-left: -40px;
    z-index: 31;
}

.popups7 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 25%;
    top: 55%;
    margin-left: -50px;
    z-index: 31;
}

.cursed-pop {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 15%;
    top: 15%;
    margin-left: -10px;
    z-index: 32;
}

.visual-worm {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 18%;
    top: 22%;
    margin-left: -10px;
    z-index: 32;
}

.visual-worm2 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 40%;
    top: 35%;
    margin-left: 0px;
    z-index: 33;
}

.visual-worm3 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 0%;
    top: 10%;
    margin-left: 0px;
    z-index: 34;
}

.visual-worm4 {
    width: auto;
    height: auto;
    padding-bottom: 30px;
    left: 5%;
    top: 52%;
    margin-left: 0px;
    z-index: 34;
}


.popups-text {
    padding:  .25rem .5rem;
    text-align: justify;
  }

.popups-title, .popups-title2, .popups-title3, .popups-title4, .popups-title5, .popups-title6, .popups-title7, .cursed-pop-title {
    background:linear-gradient(90deg,#000080, rgb(0, 0, 160));    
    color: white;
    padding: 4px;
    font-family: 'MS Sans Serif', 'Menlo', sans-serif;
    text-shadow: none;
    line-height: 1.2rem;
    min-height: 1.8rem;
    max-height: 2rem;
    overflow: hidden;
    border: 3px outset rgb(0, 0, 160);
    grid-area: title;
    min-width: 100;
    font-weight: bold;
    text-align: center;
}

.popups-title {
    animation: flash 1s infinite;
}

.popups-title2 {
    animation: flash .2s infinite;
}

.popups-title3 {
    animation: flash 2s infinite;
}

.popups-title4 {
    animation: flash 1.2s infinite;
}

.popups-title5 {
    animation: flash 1.4s infinite;
}

.popups-title6 {
    animation: flash .4s infinite;
}

.popups-title7 {
    animation: flash .75s infinite;
}

.cursed-pop-title {
    animation: cursed .2s infinite;
}

@keyframes flash {
    0% { color: white; }
    49% { color: white; }
    50% { color: transparent; }
    100% { color: transparent; }
    }


@keyframes cursed {
    0% {
        text-shadow: 1px 0 0 rgb(255, 0, 128), -2px 0 0 lime;
    }
    50% {
        text-shadow: -1px 0 0 lime, 2px 0 5 rgb(255, 0, 128);
    
    }
    75% {
        text-shadow: -2px -1px 5px rgb(92, 184, 17), 0px 1px 3px rgb(255, 0, 85);
    }
    
    100% {
        text-shadow: 2px 0.5px 3px rgb(255, 0, 85), -2px -1px 5px rgb(92, 184, 17);
    }
}

.popups-close {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);    
    margin: 4px;
    border: 1px outset rgb(129, 129, 129);
    background-color: rgb(195, 195, 195);
    padding: 2px .5rem 2px .5rem;
    font-size: 1rem;
    min-height: 1.2rem;
    font-size: small;
}

.popups-close-top {
    float: right;
    border: 1px outset rgb(129, 129, 129);
    background-color: rgb(195, 195, 195);
    padding: 0px 6px 0px 6px;
    font-size: .8rem;
    max-width: 1.5rem;
}

.popups-close-top:hover {
    background-color: rgb(220, 220, 220);
}

.popups-close-top:active {
    border: 1px inset rgb(129, 129, 129);
}

.popups-close-top:focus {
    border: 1px dotted rgb(129, 129, 129);
}

.popups-close:hover {
    background-color: rgb(219, 219, 219);
}

.popups-close:active {
    border: 1px inset rgb(129, 129, 129);
}