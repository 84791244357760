.exhibit-button {
    -webkit-border-radius: 4;
    -moz-border-radius: 4;
    border-radius: 4px;
    border: 1px solid #ffffff;
    font-family: 'Courier New', Courier, monospace;
    color: #ffffff;
    font-size: 8px;
    padding: 5px;
    background: #000000;
    text-decoration: none;
    margin: .2rem;
  }
  
  .exhibit-button:hover {
    background: #595959;
    text-decoration: none;
  }