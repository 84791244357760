.contact-body {
    text-align: center;
}

.freak-text {
    font-weight: bold;
}

a {
    font-weight: bold;
    text-decoration: none;
}

.freak-text:hover {
    animation-duration: 0.03s;
    animation-name: glitched;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: default;
}

@media screen and (max-width: 600px) {
    .contact-body {
        margin-left: auto;    
        max-width: 350px;
        min-width: 80%;
        overflow-y: hidden;
    }
    .contact-container {
        width: 80%;
    }
    .contact-image {
        max-width: 80%;
        height: auto;
        display: inline;
     } 
    .contact-image:hover {
        display: hidden;
     }
  }

.contact-image {
    max-width: 80%;
    height: auto;
    display: inline;
 }

 .contact-image:hover {
    display: hidden;
 }



@keyframes glitched {
    0% {
        text-shadow: 1px 0px 0px black, -2px 0px 0px yellow;
    }
    50% {
        text-shadow: -1px 0px 0px red, 2px 0px 1px black;
    
    }
    75% {
        text-shadow: -2px -1px 2px black, 0px 1px 0px white;
    }
    
    100% {
        text-shadow: 2px 0.5px .5px blue, -2px -1px 0px lime;
    }
    }